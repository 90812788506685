define("discourse/plugins/stemaway-project-generation/discourse/controllers/admin-plugins-ai-parameters", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax"], function (_exports, _controller, _object, _tracking, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiParametersController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked], function () {
      return {};
    }))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    updatePrompt(setting, event) {
      this.model[setting] = event.target.value;
    }
    static #_3 = (() => dt7948.n(this.prototype, "updatePrompt", [_object.action]))();
    saveSingleSetting(setting) {
      (0, _ajax.ajax)(`/admin/site_settings/${setting}`, {
        type: 'PUT',
        data: {
          [setting]: this.model[setting]
        }
      }).then(() => {
        alert('Setting saved successfully!');
      }).catch(() => {
        alert('Failed to save setting.');
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "saveSingleSetting", [_object.action]))();
    cancelChanges(setting) {
      const originalValue = this.siteSettings[setting];
      this.model = {
        ...this.model,
        [setting]: originalValue
      };
    }
    static #_5 = (() => dt7948.n(this.prototype, "cancelChanges", [_object.action]))();
  }
  _exports.default = AiParametersController;
});