define("discourse/plugins/stemaway-project-generation/discourse/connectors/topic-title/evaluation-generator", ["exports", "@ember/component", "discourse/lib/ajax", "@ember/service", "@ember/object"], function (_exports, _component, _ajax, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    evaluationService: (0, _service.inject)('evaluation-service'),
    chatStateManager: (0, _service.inject)(),
    isLoading: false,
    questions: [],
    currentQuestionIndex: 0,
    selectedAnswers: {},
    showNext: false,
    correctAnswersCount: 0,
    evaluationComplete: false,
    userPoints: null,
    displayResult: false,
    messages: [],
    userInput: '',
    timerDuration: 30,
    currentTimer: null,
    timerIntervalId: null,
    isWelcomeVisible: false,
    disableInteraction: false,
    answeredQuestions: {},
    isDropdownOpen: false,
    init() {
      this._super(...arguments);
      this.addObserver('evaluationService.displayEvaluationButton', this, this.initializeComponent);
      this.initializeComponent();
    },
    initializeComponent() {
      if (!this.evaluationService.displayEvaluationButton) {
        return;
      }
      this.displayResult = false;
      this.updateUserPoints();
      this.topicId = this.get('router.currentRoute.parent.params.id');
      this.loadEvaluationState();
      if (this.currentQuestionIndex === this.questions.length - 1 && this.currentTimer === 0) {
        this.set('evaluationComplete', true);
        this.resetEvaluation();
      }
    },
    evaluationCompleteObserver: (0, _object.observer)('evaluationComplete', function () {
      if (this.evaluationComplete) {
        this.resetEvaluation();
      }
    }),
    loadEvaluationState() {
      const topicId = this.topicId;
      const savedState = localStorage.getItem(`evaluationState-${topicId}`);
      if (savedState) {
        const state = JSON.parse(savedState);
        this.setProperties({
          questions: state.questions,
          currentQuestionIndex: state.currentQuestionIndex,
          selectedAnswers: state.selectedAnswers,
          correctAnswersCount: state.correctAnswersCount,
          evaluationComplete: state.evaluationComplete,
          hasAnswerBeenSelected: state.hasAnswerBeenSelected,
          showNext: state.showNext,
          answeredQuestions: state.answeredQuestions || {}
        });
        this.restoreTimer(state);
      }
    },
    saveState() {
      const state = {
        questions: this.questions,
        currentQuestionIndex: this.currentQuestionIndex,
        selectedAnswers: this.selectedAnswers,
        correctAnswersCount: this.correctAnswersCount,
        evaluationComplete: this.evaluationComplete,
        currentTimer: this.currentTimer,
        showNext: this.showNext,
        timerStartTimestamp: Date.now(),
        hasAnswerBeenSelected: this.hasAnswerBeenSelected,
        answeredQuestions: this.answeredQuestions
      };
      localStorage.setItem(`evaluationState-${this.topicId}`, JSON.stringify(state));
    },
    restoreTimer(state) {
      if (state.timerStartTimestamp) {
        const currentTime = Date.now();
        const elapsedSeconds = Math.floor((currentTime - state.timerStartTimestamp) / 1000);
        const adjustedTimer = Math.max(state.currentTimer - elapsedSeconds, 0);
        this.set('currentTimer', adjustedTimer);
        if (!this.evaluationComplete && adjustedTimer >= 0) {
          this.startTimer();
        }
      }
    },
    resetEvaluation() {
      clearInterval(this.timerIntervalId);
      this.evaluationService.endEvaluation();
      localStorage.removeItem(`evaluationState-${this.topicId}`);
      this.setProperties({
        currentTimer: this.timerDuration,
        timerIntervalId: null,
        questions: [],
        currentQuestionIndex: 0,
        selectedAnswers: {},
        showNext: false,
        correctAnswersCount: 0,
        evaluationComplete: false,
        answeredQuestions: {}
      });
      localStorage.removeItem('evaluationState');
    },
    willDestroyElement() {
      this._super(...arguments);
      clearInterval(this.timerIntervalId);
    },
    sufficientTacks: (0, _object.computed)('userPoints', 'siteSettings.evaluation_tacks_cost', function () {
      return this.userPoints >= this.siteSettings.evaluation_tacks_cost;
    }),
    currentTopicId: (0, _object.computed)('router.currentRoute', function () {
      return this.router.currentRoute.parent.params.id;
    }),
    isEvaluationVisible: (0, _object.computed)('currentTopicId', function () {
      return this.evaluationService.isEvaluationVisible(this.currentTopicId);
    }),
    currentQuestion: (0, _object.computed)('questions', 'currentQuestionIndex', function () {
      return this.get('questions')[this.get('currentQuestionIndex')];
    }),
    score: (0, _object.computed)('correctAnswersCount', 'questions.length', function () {
      return `${this.get('correctAnswersCount')}/${this.get('questions').length}`;
    }),
    selectedOptionForCurrentQuestion: (0, _object.computed)('selectedAnswers', 'currentQuestionIndex', function () {
      let currentAnswers = this.selectedAnswers[this.currentQuestionIndex];
      return currentAnswers ? currentAnswers : null;
    }),
    makeAPIRequest(topicContent, model) {
      let plainTextContent = topicContent.replace(/<[^>]+>/g, '');
      const prompt = `
    USE THIS CONTENT:
  
    "${plainTextContent}"
  
     ${this.siteSettings.evaluation_prompt_text.replace(/\\n/g, '\n')}
    `;
      const encodedPrompt = prompt;
      const encodedModel = model;
      return (0, _ajax.ajax)("/stemaway-project-generation/make-api-request", {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          prompt: encodedPrompt,
          model: encodedModel,
          mcq: true
        })
      });
    },
    processEvaluation() {
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-topic-content/${this.topicId}`).then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        let topicContent = response.content;
        return this.makeAPIRequest(topicContent, `main`);
      }).then(response => {
        const encodedQuestions = response.questions;
        let decodedQuestions;
        if (typeof encodedQuestions === 'string') {
          try {
            decodedQuestions = JSON.parse(encodedQuestions);
          } catch (e) {
            console.error("Error parsing questions:", e);
            decodedQuestions = [];
          }
        } else {
          decodedQuestions = encodedQuestions;
        }
        this.setProperties({
          questions: decodedQuestions.slice(0, 10),
          isWelcomeVisible: true,
          isLoading: false
        });
      }).catch(error => {
        console.error("Error during evaluation generation:", error);
        this.set('isLoading', false);
      });
    },
    startTimer() {
      if (this.currentTimer === null) {
        this.set('currentTimer', this.timerDuration);
      }
      this.set('timerIntervalId', setInterval(() => {
        if (this.currentTimer > 0) {
          this.decrementProperty('currentTimer');
          this.saveState();
        } else {
          this.send('nextQuestion');
        }
      }, 1000));
    },
    resetTimer() {
      clearInterval(this.timerIntervalId);
      this.currentTimer = null;
      this.startTimer();
    },
    updateUserPoints() {
      const username = this.get('currentUser.username');
      return (0, _ajax.ajax)("/stemaway-project-generation/fetch-user-points", {
        type: "GET",
        data: {
          username
        }
      }).then(response => {
        this.set('userPoints', response.points);
      });
    },
    submitAnswers() {
      const userAnswers = this.selectedAnswers;
      (0, _ajax.ajax)("/stemaway-project-generation/validate-answers", {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({
          userAnswers
        })
      }).then(response => {
        this.set('correctAnswersCount', response.correctAnswersCount);
        this.set('displayResult', true);
        this.set('evaluationComplete', true);
        if (this.evaluationService.remainingFreeAttempts === 0) {
          this.evaluationService.subtractPointsForEval(`MCQ Evaluation (Topic - ${this.topicId})`, this.siteSettings.evaluation_tacks_cost);
        }
        this.evaluationService.saveScore(this.get('correctAnswersCount'));
      }).catch(error => {
        console.error("Error during answer validation:", error);
      });
    },
    actions: {
      toggleDropdown() {
        this.toggleProperty('isDropdownOpen');
      },
      generateQuestions() {
        this.resetEvaluation();
        this.set('isLoading', true);
        this.set('displayResult', false);
        this.updateUserPoints().then(() => {
          if (this.evaluationService.remainingFreeAttempts === 0 && this.get('userPoints') < this.get('siteSettings.evaluation_tacks_cost')) {
            alert("Insufficient tacks.");
            this.set('isLoading', false);
          } else {
            this.processEvaluation();
          }
        }).catch(error => {
          console.error("Error updating points:", error);
          this.set('isLoading', false);
        });
      },
      startEvaluation(event) {
        event.preventDefault();
        event.stopPropagation();
        this.set('disableInteraction', true);
        setTimeout(() => {
          this.set('disableInteraction', false);
        }, 400);
        this.evaluationService.startEvaluation(this.get('router.currentRoute.parent.params.id'));
        this.set('isWelcomeVisible', false);
        this.set('hasAnswerBeenSelected', false);
        this.startTimer();
      },
      selectAnswer(questionIndex, option) {
        if (this.answeredQuestions[questionIndex]) {
          return;
        }
        let updateSelectedAnswers = {
          ...this.selectedAnswers,
          [questionIndex]: option
        };
        this.set('selectedAnswers', updateSelectedAnswers);
        this.setProperties({
          hasAnswerBeenSelected: true,
          showNext: true
        });
        this.set(`answeredQuestions.${questionIndex}`, true);
        this.saveState();
      },
      nextQuestion() {
        if (this.currentQuestionIndex < this.questions.length - 1) {
          this.incrementProperty('currentQuestionIndex');
          this.set('hasAnswerBeenSelected', false);
        } else {
          this.submitAnswers();
        }
        this.set('showNext', false);
        this.resetTimer();
        this.saveState();
      },
      updateUserInput(value) {
        this.set('userInput', value);
      }
    }
  });
  function utf8ToBase64(str) {
    const utf8Bytes = new TextEncoder().encode(str);
    return btoa(String.fromCharCode(...utf8Bytes));
  }
  function base64ToUtf8(str) {
    const binaryString = atob(str);
    const binaryArray = Uint8Array.from(binaryString, char => char.charCodeAt(0));
    return new TextDecoder().decode(binaryArray);
  }
});