define("discourse/plugins/stemaway-project-generation/discourse/services/evaluation-service", ["exports", "@ember/service", "discourse/lib/ajax", "@ember/object"], function (_exports, _service, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    siteSettings: (0, _service.inject)(),
    topicId: null,
    previousScore: null,
    displayEvaluationButton: false,
    displayCodeAlongButton: false,
    firstGreeting: true,
    currentEvaluationTopicId: null,
    evaluationInProgress: false,
    userAttempts: null,
    parsedScore: null,
    init() {
      this._super(...arguments);
      this._updateValuesBasedOnRoute();
      this.router.on('routeDidChange', () => {
        this._updateValuesBasedOnRoute();
      });
    },
    _updateValuesBasedOnRoute() {
      this.set('topicId', this.get('router.currentRoute.parent.params.id'));
      this.restoreEvaluationState();
      this.checkIfTopicInProjectCategory().then(() => {});
    },
    loadUserAttempts() {
      return (0, _ajax.ajax)(`/stemaway-project-generation/fetch-user-attempts/${this.topicId}`).then(response => {
        const attempts = parseInt(response.attempts, 10) || 0;
        this.set('userAttempts', attempts);
      });
    },
    remainingFreeAttempts: (0, _object.computed)('userAttempts', function () {
      const attempts = this.userAttempts || 0;
      const freeAttempts = this.siteSettings.evaluation_free_attempts;
      return Math.max(0, freeAttempts - attempts);
    }),
    incrementUserAttempts() {
      return (0, _ajax.ajax)({
        url: `/stemaway-project-generation/increment-user-attempts/${this.topicId}`,
        type: 'POST'
      }).then(() => {
        return this.loadUserAttempts();
      }).then(() => {
        this.notifyPropertyChange('userAttempts');
      });
    },
    restoreEvaluationState() {
      let savedState = localStorage.getItem('currentEvaluationState');
      if (savedState) {
        savedState = JSON.parse(savedState);
        this.setProperties({
          currentEvaluationTopicId: savedState.currentEvaluationTopicId,
          evaluationInProgress: savedState.evaluationInProgress
        });
      }
    },
    startEvaluation(topicId) {
      this.setProperties({
        currentEvaluationTopicId: topicId,
        evaluationInProgress: true
      });
      this.saveEvaluationState();
    },
    endEvaluation() {
      this.setProperties({
        currentEvaluationTopicId: null,
        evaluationInProgress: false
      });
      this.saveEvaluationState();
    },
    saveEvaluationState() {
      const state = {
        currentEvaluationTopicId: this.currentEvaluationTopicId,
        evaluationInProgress: this.evaluationInProgress
      };
      localStorage.setItem('currentEvaluationState', JSON.stringify(state));
    },
    isEvaluationVisible(topicId) {
      if (this.evaluationInProgress) {
        return this.evaluationInProgress && this.currentEvaluationTopicId === topicId;
      } else {
        return true;
      }
    },
    checkIfTopicInProjectCategory() {
      return (0, _ajax.ajax)('/stemaway-project-generation/fetch-topics-in-project-category').then(response => {
        let topicIds = response.topic_ids;
        if (topicIds.includes(parseInt(this.topicId, 10))) {
          this.fetchPreviousScore();
          this.loadUserAttempts();
          this.set('displayEvaluationButton', true);
        } else {
          this.set('displayEvaluationButton', false);
        }
      }).catch(error => {
        // console.error('Error fetching topics:', error);
      });
    },
    fetchPreviousScore() {
      let topicId = this.topicId;
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-topic-score/${topicId}`).then(response => {
        if (response.score && response.score !== "No score available") {
          this.set('previousScore', response.score);
          this.extractAndSaveScore();
        } else {
          this.set('previousScore', null);
        }
      }).catch(error => {
        console.error('Error fetching previous score:', error);
        this.set('previousScore', null);
      });
    },
    extractAndSaveScore() {
      if (this.previousScore && typeof this.previousScore === 'string') {
        let score = parseInt(this.previousScore.split('/')[0], 10);
        this.set('parsedScore', score);
      } else if (typeof this.previousScore === 'number') {
        this.set('parsedScore', this.previousScore);
        this.set('previousScore', `${this.previousScore}/10`);
      } else {
        this.set('parsedScore', null);
      }
    },
    subtractPointsForEval(reason, pointsToSubtract) {
      const username = this.currentUser.username;
      const data = {
        username,
        points: pointsToSubtract,
        reason
      };
      (0, _ajax.ajax)("/stemaway-project-generation/subtract-user-points", {
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json"
      }).catch(error => {
        console.error("Failed to subtract points: ", error);
      });
    },
    saveScore(score) {
      let topicId = this.topicId;
      this.incrementUserAttempts(topicId);
      let formattedScore = `${score}/10`;
      (0, _ajax.ajax)({
        url: `/stemaway-project-generation/save-topic-score`,
        type: 'POST',
        data: {
          topic_id: topicId,
          score: formattedScore
        }
      }).then(() => {
        this.set('previousScore', formattedScore);
        this.extractAndSaveScore();
      }).catch(error => {
        console.error('Error saving score:', error);
      });
    }
  });
});