define("discourse/plugins/stemaway-project-generation/discourse/components/project-generation", ["exports", "@ember/component", "discourse/lib/ajax", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/models/category", "@ember/runloop"], function (_exports, _component, _ajax, _object, _service, _ajaxError, _category, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    projectState: (0, _service.inject)(),
    skills: '',
    isLoading: false,
    buttonText: 'Start',
    isError: false,
    isDisabled: false,
    isButtonHidden: false,
    messageSent: false,
    responseReceived: false,
    slideLeftAndUp: false,
    showNoTopicsFoundPrompt: false,
    extractedSkills: '',
    extractedSkillsArray: [],
    avatarVisible: false,
    apologyMessage: '',
    userInput: '',
    extractedtopicslist: '',
    elementShouldBeHidden: false,
    availableSkills: [],
    additionalSkills: '',
    showAdditionalSkillsInput: false,
    selectedSkillsArray: [],
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    siteSettings: (0, _service.inject)(),
    isInCategory: (0, _object.computed)('router.currentURL', function () {
      let projectCategoryId = this.siteSettings.project_category;
      let categoryId = parseInt(projectCategoryId, 10);
      if (isNaN(categoryId)) {
        console.error('Invalid category ID:', projectCategoryId);
        return false;
      }
      let category = _category.default.findById(categoryId);
      if (category) {
        let categorySlug = category.slug;
        return this.router.currentURL.includes(`c/${categorySlug}/${categoryId}`);
      }
      return false;
    }),
    hasAccess: (0, _object.computed)('currentUser', 'siteSettings.project_generation_access_group', function () {
      const accessGroup = this.siteSettings.project_generation_access_group;
      if (!accessGroup) {
        return true;
      }
      return this.currentUser && this.currentUser.groups.some(group => group.name === accessGroup);
    }),
    searchQuery: '',
    additionalSkills: '',
    filteredSkills: (0, _object.computed)('searchQuery', function () {
      let query = this.get('searchQuery').toLowerCase();
      if (query) {
        return this.get('availableSkills').filter(skill => skill.toLowerCase().includes(query));
      } else {
        return [];
      }
    }),
    init() {
      this._super(...arguments);
      if (!this.isInCategory) {
        return;
      }
      if (!this.hasAccess) {
        return;
      }
      (0, _ajax.ajax)('/plugins/stemaway-project-generation/json/sorted_skills.json').then(skills => {
        this.set('availableSkills', skills);
      }).catch(error => {
        console.error('Error fetching skills:', error);
      });
    },
    isLoggedIn: (0, _object.computed)('currentUser', function () {
      return !!this.get('currentUser.currentUser');
    }),
    requiredPoints: (0, _object.computed)('siteSettings.required_tacks', function () {
      return this.get('siteSettings.required_tacks');
    }),
    ismyButtonDisabled: (0, _object.computed)('skills', 'isLoading', 'isDisabled', function () {
      return !this.get('skills').trim() || this.get('isLoading') || this.get('isDisabled');
    }),
    isInputEmpty: (0, _object.computed)('skills', function () {
      return !this.get('skills').trim();
    }),
    showAvatar: (0, _object.computed)('avatarVisible', 'isInputEmpty', function () {
      return this.get('avatarVisible') && !this.get('isInputEmpty');
    }),
    isProjectGenButtonDisabled: (0, _object.computed)('userPoints', 'requiredPoints', function () {
      return this.get('userPoints') < this.get('requiredPoints');
    }),
    findSimilarTopics(topics, skills) {
      return topics.filter(topic => {
        return skills.some(skill => topic.tags.includes(skill.toLowerCase()));
      });
    },
    noTopicsFound() {
      this.set('showNoTopicsFoundPrompt', true);
    },
    fetchUserPoints() {
      const username = this.get('currentUser.username');
      return (0, _ajax.ajax)("/stemaway-project-generation/fetch-user-points", {
        type: "GET",
        data: {
          username
        }
      }).then(response => {
        this.set('userPoints', response.points);
      }).catch(_ajaxError.popupAjaxError);
    },
    subtractPointsForProject(originalTopicId) {
      this.projectState.subtractPointsForProject(`AI Project Generation (Topic - ${originalTopicId})`);
    },
    extractSkills(skillString) {
      const skillsAfterColon = skillString.split(':').pop();
      const regex = /(?:\([^)]*\)|[^,])+/g;
      return skillsAfterColon.match(regex).map(skill => skill.trim().toLowerCase());
    },
    setErrorState() {
      this.setProperties({
        isError: true,
        buttonText: 'Error',
        isDisabled: true,
        responseReceived: true,
        isButtonHidden: false
      });
      setTimeout(() => {
        this.setProperties({
          buttonText: 'Start',
          isDisabled: false,
          isError: false
        });
      }, 3000);
    },
    setLoadingState(isLoading) {
      this.set('isLoading', isLoading);
      this.set('buttonText', isLoading ? 'Processing...' : 'Start');
    },
    makeAPIRequest(prompt, model) {
      return (0, _ajax.ajax)("/stemaway-project-generation/make-api-request", {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          prompt: prompt,
          model: model,
          mcq: false
        })
      });
    },
    extractObjectiveLine(content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const text = doc.body.textContent || "";
      const objectiveMatch = text.match(/Objective:?\s*(.*?)\s*(\n|\.)/i);
      if (objectiveMatch && objectiveMatch.length > 1) {
        return objectiveMatch[1].trim() + '...';
      }
      return '';
    },
    actions: {
      updateSkills(newValue) {
        this.set('skills', newValue);
        if (newValue.trim()) {
          this.set('isButtonHidden', false);
        } else {
          this.set('messageSent', false);
          this.set('isButtonHidden', true);
        }
      },
      updateQuery(value) {
        this.set('searchQuery', value);
      },
      generateNewTopic() {
        if (this.isProjectGenButtonDisabled) {
          alert("Insufficient tacks.");
        } else {
          this.send('generateProject');
        }
      },
      removeSkill(skillToRemove) {
        let updatedSkillsArray = this.get('extractedSkillsArray').filter(skill => skill !== skillToRemove);
        this.set('extractedSkillsArray', updatedSkillsArray);
        let updatedSkillsString = updatedSkillsArray.join(', ');
        this.set('extractedSkills', updatedSkillsString);
        if (this.get('extractedSkillsArray.length') > 0) {
          this.send('checkSimilarTopicsWithUpdatedSkills', updatedSkillsString);
        } else {
          this.set('messageSent', true);
          this.set('responseReceived', false);
          this.set('isError', false);
          this.set('isDisabled', false);
          this.set('similarTopics', null);
          this.element.querySelector('.input-container').classList.add('message-returning');
          this.send('resetGenerationState');
        }
      },
      toggleAdditionalSkillsInput() {
        if (!this.showAdditionalSkillsInput) {
          let suggestedSkills = this.get('extractedSkillsArray').join(', ');
          this.set('additionalSkills', suggestedSkills);
        }
        this.toggleProperty('showAdditionalSkillsInput');
      },
      selectSkill(skill) {
        let selectedSkillsArray = this.get('selectedSkillsArray') || [];
        if (!selectedSkillsArray.includes(skill)) {
          selectedSkillsArray.pushObject(skill);
          this.set('selectedSkillsArray', selectedSkillsArray);
        }
        this.set('searchQuery', '');
      },
      removeAdditionalSelectedSkill(skillToRemove) {
        let selectedSkillsArray = this.get('selectedSkillsArray');
        let updatedSkillsArray = selectedSkillsArray.filter(skill => skill !== skillToRemove);
        this.set('selectedSkillsArray', updatedSkillsArray);
      },
      addAdditionalSkills() {
        let selectedSkillsArray = this.get('selectedSkillsArray') || [];
        let normalizedSkillsArray = selectedSkillsArray.map(skill => skill.trim().replace(/\s+/g, '-'));
        let updatedSkills = normalizedSkillsArray.join(', ');
        if (updatedSkills.trim()) {
          let extract = this.get('extractedSkills');
          let newSkills = extract ? `${extract}, ${updatedSkills}` : updatedSkills;
          this.send('checkSimilarTopicsWithUpdatedSkills', newSkills);
        }
        this.set('selectedSkillsArray', []);
        this.set('showAdditionalSkillsInput', false);
      },
      checkSimilarTopicsWithUpdatedSkills(updatedSkills) {
        const fetchAllTopics = function (url) {
          let allTopics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
          return (0, _ajax.ajax)(url).then(response => {
            let topics = response.topic_list.topics.map(topic => ({
              id: topic.id,
              title: topic.title,
              tags: topic.tags,
              pathway: topic.pathway,
              skill: topic.skill,
              subSkill: topic.subSkill
            }));
            allTopics = allTopics.concat(topics);
            if (response.topic_list.more_topics_url) {
              let nextUrl = response.topic_list.more_topics_url;
              if (nextUrl.charAt(0) === '/') {
                nextUrl = window.location.origin + nextUrl;
              }
              return fetchAllTopics(nextUrl, allTopics);
            } else {
              return allTopics;
            }
          });
        };
        const processTopics = topics => {
          let skillsArray = updatedSkills.split(',').map(skill => skill.trim().toLowerCase());
          const filteredTopics = topics.filter(topic => skillsArray.some(skill => topic.tags && topic.tags.includes(skill) || topic.pathway && Array.isArray(topic.pathway) && topic.pathway.some(p => p.toLowerCase().includes(skill)) || topic.skill && Array.isArray(topic.skill) && topic.skill.some(s => s.toLowerCase().includes(skill)) || topic.subSkill && Array.isArray(topic.subSkill) && topic.subSkill.some(s => s.toLowerCase().includes(skill))));
          if (filteredTopics.length === 0) {
            this.set('similarTopics', null);
            this.set('isButtonHidden', true);
            this.set('responseReceived', true);
            this.set('showNoTopicsFoundPrompt', true);
            this.noTopicsFound();
            return;
          }
          let topicIds = filteredTopics.map(topic => topic.id).join(',');
          (0, _ajax.ajax)(`/api/discourse/fetch_topics_batch?topic_ids=${topicIds}`).then(batchResponse => {
            let batchTopics = batchResponse.topics;
            let processedTopics = batchTopics.map(topic => {
              let objectiveLine = this.extractObjectiveLine(topic.first_post);
              return {
                ...topic,
                objectiveLine
              };
            });
            const uniqueTopics = processedTopics.filter((topic, index, self) => index === self.findIndex(t => t.id === topic.id));
            if (uniqueTopics.length > 0) {
              this.set('similarTopics', uniqueTopics);
              (0, _runloop.next)(this, () => {
                this.element.querySelector('.conversation-container').classList.add('loaded');
              });
              this.set('isButtonHidden', true);
              this.set('responseReceived', true);
              this.set('showNoTopicsFoundPrompt', false);
            } else {
              this.set('similarTopics', null);
              this.set('isButtonHidden', true);
              this.set('responseReceived', true);
              this.set('showNoTopicsFoundPrompt', true);
              this.noTopicsFound();
            }
            let updatedSkillsArray = updatedSkills.split(',').map(skill => skill.trim().toLowerCase());
            this.set('extractedSkillsArray', updatedSkillsArray);
            this.set('extractedSkills', updatedSkillsArray.join(', '));
          }).catch(error => {
            console.error('Error fetching topic details in batch:', error);
          });
        };
        if (this.cachedTopics) {
          processTopics(this.cachedTopics);
        } else {
          const categoryUrl = `/c/${this.siteSettings.project_category}.json`;
          fetchAllTopics(categoryUrl).then(topics => {
            this.cachedTopics = topics;
            processTopics(topics);
          });
        }
      },
      resetGenerationState() {
        this.setProperties({
          skills: '',
          buttonText: 'Start',
          showNoTopicsFoundPrompt: false,
          messageSent: false,
          avatarVisible: false,
          responseReceived: false,
          similarTopics: null,
          showAdditionalSkillsInput: false,
          selectedSkillsArray: []
        });
        let inputContainer = this.element.querySelector('.input-container');
        (0, _runloop.next)(this, function () {
          inputContainer.classList.remove('slide-right');
          inputContainer.classList.add('slide-back');
        });
      },
      checkSimilarTopics() {
        this.set('slideLeftAndUp', false);
        this.set('elementShouldBeHidden', false);
        this.set('apologyMessage', '');
        this.fetchUserPoints();
        this.setLoadingState(true);
        this.set('messageSent', true);
        this.set('avatarVisible', true);
        this.set('userInput', this.get('skills'));
        let prompt = this.siteSettings.skill_extraction_prompt + this.userInput;
        this.makeAPIRequest(prompt, `main`).then(response => {
          let content = response.trim();
          // console.log(content);
          if (/\bsorry\b/.test(content.toLowerCase()) || /\bhello\b/.test(content.toLowerCase()) || /\bhi\b/.test(content.toLowerCase()) || /\berror\b/.test(content.toLowerCase()) || /\bno skills\b/.test(content.toLowerCase()) || /\bthank you\b/.test(content.toLowerCase())) {
            this.set('apologyMessage', "I'm sorry, but I need more information to provide you with the skills, libraries, and tools you are looking for. Could you please specify the broad domain or field you are referring to?");
            this.set('extractedSkills', '');
            this.set('extractedSkillsArray', []);
            this.set('buttonText', 'Start');
            this.send('resetGenerationState');
            this.setLoadingState(false);
          } else {
            let extractedSkills = this.extractSkills(content);
            this.set('extractedSkills', extractedSkills.join(', '));
            this.set('extractedSkillsArray', extractedSkills);
            this.send('fetchTopicsAndExtractFirstLine', extractedSkills);
          }
        }).catch(error => {
          this.setLoadingState(false);
          this.set('isError', true);
          this.setErrorState();
        }).finally(() => {
          this.setLoadingState(false);
        });
      },
      fetchTopicsAndExtractFirstLine(skills) {
        if (typeof skills !== 'string') {
          skills = skills.toString();
        }
        const fetchAllTopics = function (url) {
          let allTopics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
          return (0, _ajax.ajax)(url).then(response => {
            let topics = response.topic_list.topics.map(topic => ({
              id: topic.id,
              title: topic.title,
              tags: topic.tags,
              pathway: topic.pathway,
              skill: topic.skill,
              subSkill: topic.subSkill
            }));
            allTopics = allTopics.concat(topics);
            if (response.topic_list.more_topics_url) {
              let nextUrl = response.topic_list.more_topics_url;
              if (nextUrl.charAt(0) === '/') {
                nextUrl = window.location.origin + nextUrl;
              }
              return fetchAllTopics(nextUrl, allTopics);
            } else {
              return allTopics;
            }
          });
        };
        const processFetchedTopics = topics => {
          let skillTags = skills.split(',').map(skill => skill.trim().toLowerCase());
          const filteredTopics = topics.filter(topic => skillTags.some(tag => topic.tags && topic.tags.includes(tag) || topic.pathway && Array.isArray(topic.pathway) && topic.pathway.some(p => p.toLowerCase().includes(tag)) || topic.skill && Array.isArray(topic.skill) && topic.skill.some(s => s.toLowerCase().includes(tag)) || topic.subSkill && Array.isArray(topic.subSkill) && topic.subSkill.some(s => s.toLowerCase().includes(tag))));
          if (filteredTopics.length === 0) {
            this.set('similarTopics', null);
            this.set('isButtonHidden', true);
            this.set('responseReceived', true);
            this.noTopicsFound();
            return;
          }
          let topicIds = filteredTopics.map(topic => topic.id).join(',');
          (0, _ajax.ajax)(`/api/discourse/fetch_topics_batch?topic_ids=${topicIds}`).then(batchResponse => {
            let batchTopics = batchResponse.topics;
            let processedTopics = batchTopics.map(topic => {
              let objectiveLine = this.extractObjectiveLine(topic.first_post);
              return {
                ...topic,
                objectiveLine
              };
            });
            if (processedTopics.length > 0) {
              this.set('similarTopics', processedTopics);
              (0, _runloop.next)(this, () => {
                this.element.querySelector('.conversation-container').classList.add('loaded');
              });
              this.set('isButtonHidden', true);
              this.set('responseReceived', true);
              let topicslist = processedTopics.map(topic => topic.title);
              this.set('extractedtopicslist', topicslist);
            } else {
              this.set('similarTopics', null);
              this.set('isButtonHidden', true);
              this.set('responseReceived', true);
              this.noTopicsFound();
            }
            setTimeout(() => {
              this.setLoadingState(false);
            }, 0);
          }).catch(error => {
            console.error('Error fetching topics in batch:', error);
            this.setLoadingState(false);
            this.set('isError', true);
            this.setErrorState();
          });
        };
        if (this.cachedTopics) {
          processFetchedTopics(this.cachedTopics);
        } else {
          const categoryUrl = `/c/${this.siteSettings.project_category}.json`;
          fetchAllTopics(categoryUrl).then(topics => {
            this.cachedTopics = topics;
            processFetchedTopics(topics);
          }).catch(error => {
            console.error('Error fetching topics:', error);
            this.setLoadingState(false);
            this.set('isError', true);
            this.setErrorState();
          });
        }
      },
      inputChanged() {
        this.setProperties({
          isButtonHidden: false,
          buttonText: 'Start',
          showNoTopicsFoundPrompt: false,
          similarTopics: null,
          showAdditionalSkillsInput: false,
          selectedSkillsArray: []
        });
        this.set('isDisabled', false);
      },
      generateProject() {
        this.set('slideLeftAndUp', true);
        this.set('responseReceived', true);
        setTimeout(() => {
          this.set('elementShouldBeHidden', true);
        }, 500);
        (0, _runloop.later)(() => {
          this.send('resetGenerationState');
          this.projectState.setLoadingState(true, "Generating Project");
        }, 200);
        this.set('isError', false);
        this.set('isDisabled', false);
        let skills = this.get('extractedSkills');
        let topicTitles = [];
        let originalTopicId;
        let newTopicCodeAlong;
        let mainPostContent;
        let mainTitle;
        (0, _ajax.ajax)('/api/discourse/fetch_topics', {
          type: 'GET',
          data: {
            category_id: this.siteSettings.project_category
          }
        }).then(response => {
          // console.log("Fetch Topics Response:", response);

          if (!response || !response.topic_list || !response.topic_list.topics) {
            this.setErrorState();
            this.projectState.setLoadingState(false);
          }
          topicTitles = response.topic_list.topics.map(topic => topic.title);
          topicTitles.shift();
          let itemcount = 0;
          let topicListToUse = '';
          if (this.extractedtopicslist.length > 0) {
            topicListToUse = this.extractedtopicslist;
            itemcount = Math.round(1.35 * topicListToUse.join(" ").split(" ").length);
          }
          let mainProjectPrompt = this.siteSettings.project_generation_prompt.replace('{{skills}}', skills).replace('{{userInput}}', this.userInput).replace('{{topicListToUse}}', topicListToUse);
          return this.makeAPIRequest(mainProjectPrompt, `custom`);
        }).then(response => {
          const content = response.trim();
          // console.log('content:', content);
          const cleanContent = content.replace(/^content:\s*/i, '').trim();
          let title = 'New Project';
          let mainPostContent = '';
          if (/^\*\*Title:\*\*/im.test(cleanContent)) {
            let titleMatch = cleanContent.match(/^\*\*Title:\*\*\s*(.*)$/im);
            title = titleMatch ? titleMatch[1].trim() : 'New Project';
            mainPostContent = cleanContent.replace(/^\*\*Title:\*\*\s*.*$/im, '').trim();
          } else {
            const lines = cleanContent.split(/\r?\n/);
            title = lines[0].trim();
            mainPostContent = lines.slice(1).join('\n').trim();
          }
          mainTitle = title;
          const skillsArray = skills.split(',').map(skill => skill.trim().toLowerCase());
          return (0, _ajax.ajax)('/api/discourse/create_post', {
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              title: mainTitle,
              raw: mainPostContent,
              category: this.siteSettings.project_category,
              tags: skillsArray
            })
          });
        }).then(response => {
          originalTopicId = response.topic_id;
          this.subtractPointsForProject(originalTopicId);
          let codeExtractionPrompt = this.siteSettings.code_extraction_prompt.replace('{{mainPostContent}}', mainPostContent);
          this.projectState.setLoadingState(true, "Processing Code Along");
          return this.makeAPIRequest(codeExtractionPrompt, `main`);
        }).then(codeExtractionResponse => {
          let extractedCode = codeExtractionResponse.trim();
          return (0, _ajax.ajax)('/api/discourse/create_post', {
            type: 'POST',
            data: {
              title: `Code Along for ${mainTitle}`,
              raw: extractedCode,
              category: this.siteSettings.code_along_category
            }
          });
        }).then(response => {
          newTopicCodeAlong = response.topic_id;
          const mentorAssignmentPost = `Access the [Code-Along](/t/${newTopicCodeAlong}) for this Skill-Builder Project to join discussions, utilize the t3 AI Mentor, and more.`;
          return (0, _ajax.ajax)('/api/discourse/create_reply', {
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              topic_id: originalTopicId,
              raw: mentorAssignmentPost
            })
          });
        }).then(() => {
          setTimeout(() => {}, 3000);
        }).catch(error => {
          console.error("Error during project generation:", error);
          this.setErrorState();
          this.projectState.setLoadingState(false);
        }).finally(() => {
          this.projectState.setLoadingState(false);
        });
      }
    }
  });
});